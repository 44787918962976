import { usePersistState } from '@swe/shared/providers/persist-state';

import { CommonQueryParams, RouteQuery, RouteQueryParam } from '@swe/shared/providers/router/constants';
import { capitalize } from '@swe/shared/utils/string';

import { useCallback } from 'react';

import { useStoreConfig } from 'common/providers/config';
import { useRouterNavigate, useRouterQuery } from 'common/router';
import { useStoreInfo } from 'common/use-cases/use-store-info';
import { ProductSaleType } from 'entities/product/product';
import { StoreAvailableSaleType } from 'entities/shop/config';
import { StoreSaleType } from 'entities/shop/sale-type';

const SALE_TYPE_STORAGE_KEY = 'Cart/SaleType';
const DEFAULT_SALE_TYPE = ProductSaleType.RECREATIONAL;

type UseSaleTypeReturn = { availableSaleTypes: StoreAvailableSaleType[] } & (
  | {
      medicalMenuEnabled: true;
      saleType: Exclude<StoreSaleType, undefined>;
      setSaleType: (saleType: StoreSaleType) => Promise<void>;
    }
  | {
      medicalMenuEnabled: false;
      saleType: undefined;
      setSaleType: undefined;
    }
);

type RequiredSaleType = Exclude<StoreSaleType, undefined>;

const isExistedSaleTypeParam = (saleType?: RouteQueryParam) => {
  if (!saleType) {
    return false;
  }
  return [ProductSaleType.MEDICAL.toLowerCase(), ProductSaleType.RECREATIONAL.toLowerCase()].includes(String(saleType));
};

const getSaleTypeFallback = ({
  medicalMenuEnabled,
  saleTypeBasedRoutingEnabled,
  saleTypeCookie: _saleTypeCookie,
  query,
}: {
  medicalMenuEnabled: boolean;
  saleTypeBasedRoutingEnabled: boolean;
  saleTypeCookie?: string | null;
  query: RouteQuery;
}): StoreSaleType => {
  if (!medicalMenuEnabled) {
    return undefined;
  }
  const saleTypeInitialSearchParam = isExistedSaleTypeParam(query[CommonQueryParams.SaleTypeInitialSearchParam])
    ? (capitalize(String(query[CommonQueryParams.SaleTypeInitialSearchParam])) as StoreSaleType)
    : undefined;

  const saleTypeParam =
    saleTypeBasedRoutingEnabled && isExistedSaleTypeParam(query[CommonQueryParams.SaleType])
      ? (capitalize(String(query[CommonQueryParams.SaleType])) as StoreSaleType)
      : undefined;

  const saleTypeCookie = isExistedSaleTypeParam(_saleTypeCookie?.toLowerCase())
    ? (_saleTypeCookie as StoreSaleType)
    : undefined;

  const saleType = saleTypeInitialSearchParam ?? saleTypeParam ?? saleTypeCookie ?? DEFAULT_SALE_TYPE;

  return saleType;
};

const useSaleType = () => {
  const { medicalMenuEnabled, saleTypeBasedRoutingEnabled } = useStoreConfig();
  const storeInfo = useStoreInfo();
  const navigate = useRouterNavigate();
  const query = useRouterQuery();
  const availableSaleTypes = storeInfo?.saleTypes ?? [];

  const [saleTypeCookie, setSaleTypeCookie] = usePersistState<Exclude<StoreSaleType, undefined> | null>(
    'cookies',
    SALE_TYPE_STORAGE_KEY,
    null,
  );

  const saleType = getSaleTypeFallback({ saleTypeCookie, query, medicalMenuEnabled, saleTypeBasedRoutingEnabled });

  if (saleType && saleTypeCookie && saleType !== saleTypeCookie) {
    setSaleTypeCookie(saleType ?? null);
  }

  const setSaleType = useCallback(
    async (nextSaleType: StoreSaleType) => {
      const nextQuery = { ...query };
      let queryWasChanged = false;
      if (saleTypeBasedRoutingEnabled && medicalMenuEnabled) {
        nextQuery[CommonQueryParams.SaleType] = nextSaleType?.toLowerCase();
        queryWasChanged = true;
      }
      if (nextQuery[CommonQueryParams.SaleTypeInitialSearchParam]) {
        delete nextQuery[CommonQueryParams.SaleTypeInitialSearchParam];
        queryWasChanged = true;
      }
      if (queryWasChanged) {
        await navigate({ query: nextQuery }, { replace: true });
      }
      setSaleTypeCookie(nextSaleType ?? null);
    },
    [setSaleTypeCookie, navigate, query, saleTypeBasedRoutingEnabled, medicalMenuEnabled],
  );

  return {
    saleType,
    setSaleType,
    medicalMenuEnabled,
    availableSaleTypes,
  } as UseSaleTypeReturn;
};

export type { RequiredSaleType };
export { useSaleType, getSaleTypeFallback, SALE_TYPE_STORAGE_KEY, DEFAULT_SALE_TYPE, isExistedSaleTypeParam };
