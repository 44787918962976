type RouteQueryParam = string | string[] | undefined;
type RouteQuery = Record<string, RouteQueryParam>;
type RoutePath = `/${string}`;
type RouteUrl<R extends RoutePath> = {
  pathname?: R;
  query?: RouteQuery;
  basePath?: RoutePath;
  // query?: ExtractParams<R>;
};
type Route<R extends RoutePath> = RouteUrl<R> | R;

enum CommonQueryParams {
  Modal = 'modal',
  ModalParams = 'modal-params',
  Filters = 'filters',
  Search = 'searchTerm',
  Page = 'page',
  Sorting = 'sorting',
  URLDecorator = 'decorator',
  SaleType = 'saleType',
  SaleTypeInitialSearchParam = 'saletype',
}

type ExtractParam<Path, NextPart> = Path extends `[[...${infer Param}]]`
  ? { [key in Param]: (string | number)[] } & NextPart
  : Path extends `[${infer Param}]`
    ? { [key in Param]: string | number } & NextPart
    : NextPart;

type ExtractParams<Path> = Path extends `${infer Segment}/${infer Rest}`
  ? ExtractParam<Segment, ExtractParams<Rest>>
  : ExtractParam<Path, Record<string, never>>;

export { CommonQueryParams };
export type { ExtractParams, Route, RouteUrl, RouteQuery, RouteQueryParam, RoutePath };
