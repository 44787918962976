import { XSweedHeader } from '@swe/shared/network/endpoint-factories/modern/fetcher';
import { QueryClient } from '@swe/shared/network/transport/query';
import { isOurKey } from '@swe/shared/providers/persist-state/constants';
import CookieStorage from '@swe/shared/providers/persist-state/cookie-storage';
import { CommonQueryParams, RouteQuery } from '@swe/shared/providers/router/constants';
import { stringifyRouteUrl } from '@swe/shared/providers/router/utils';
import { getCookies } from '@swe/shared/tools/cookie';
import { IntlCore } from '@swe/shared/tools/intl/core/i18n';
import { getIsPWA } from '@swe/shared/tools/is-pwa';
import { isSSR } from '@swe/shared/utils/environment';

import { memoize } from '@swe/shared/utils/func';
import { pickByKey } from '@swe/shared/utils/object';

import { RouteObject, redirect, generatePath } from 'react-router-dom';

import AppLayout from '@swe/shop-ui/app/layout';
import REDIRECTS, { TRUNCATED_SHOP_REDIRECTS } from '@swe/shop-ui/app/router/redirects';
import { ApplicationPage, PageLoaderData } from '@swe/shop-ui/app/types';
import { Routes } from '@swe/shop-ui/common/router/constants';
import { convertReactToSharedQuery, convertSharedToReactRoute } from '@swe/shop-ui/common/router/utils';
import { getPlatformOs } from '@swe/shop-ui/common/use-cases/use-platform-os';
import { ShopConfig } from '@swe/shop-ui/entities/shop/config';
import RouterErrorPage, { RootRouterErrorPage } from 'app/error-page';
import CoreLayout from 'app/layout/core';
import { parseUA } from 'app/utils';
import {
  DEFAULT_SALE_TYPE,
  getSaleTypeFallback,
  isExistedSaleTypeParam,
  SALE_TYPE_STORAGE_KEY,
} from 'common/use-cases/use-sale-type';
import GetShopInfoEndpoint from 'endpoints/shop/get-shop-info';
import { StoreSaleType } from 'entities/shop/sale-type';

type RouterConfig = {
  isTruncatedShop: boolean;
};

type RouterContext = {
  storeConfig: ShopConfig;
  queryClient: QueryClient;
  intl: IntlCore;
};

const getSaleType = ({
  cookie,
  storeConfig,
  route,
  query,
}: {
  cookie?: string;
  route: Routes;
  query: RouteQuery;
  storeConfig: ShopConfig;
}) => {
  const { medicalMenuEnabled, saleTypeBasedRoutingEnabled, routeName } = storeConfig;
  const storagesFallback = {
    cookies: pickByKey(getCookies({ cookie }), isOurKey),
  };
  const saleType = getSaleTypeFallback({
    medicalMenuEnabled,
    saleTypeBasedRoutingEnabled,
    saleTypeCookie: new CookieStorage(storagesFallback.cookies).getItem<Exclude<StoreSaleType, undefined>>(
      SALE_TYPE_STORAGE_KEY,
    ),
    query,
  });

  let queryToRedirect: RouteQuery | null = null;

  const _saleTypeBasedRoutingEnabled = medicalMenuEnabled && saleTypeBasedRoutingEnabled;

  if (
    !medicalMenuEnabled &&
    (query[CommonQueryParams.SaleType] || query[CommonQueryParams.SaleTypeInitialSearchParam])
  ) {
    queryToRedirect = {
      ...query,
      [CommonQueryParams.SaleType]: undefined,
      [CommonQueryParams.SaleTypeInitialSearchParam]: undefined,
    };
  } else if (!_saleTypeBasedRoutingEnabled && query[CommonQueryParams.SaleType]) {
    queryToRedirect = {
      ...query,
      [CommonQueryParams.SaleType]: undefined,
    };
  } else if (
    !_saleTypeBasedRoutingEnabled &&
    query[CommonQueryParams.SaleTypeInitialSearchParam] &&
    !isExistedSaleTypeParam(query[CommonQueryParams.SaleTypeInitialSearchParam])
  ) {
    queryToRedirect = {
      ...query,
      [CommonQueryParams.SaleTypeInitialSearchParam]: undefined,
    };
  } else if (
    _saleTypeBasedRoutingEnabled &&
    (!isExistedSaleTypeParam(query[CommonQueryParams.SaleType]) || query[CommonQueryParams.SaleTypeInitialSearchParam])
  ) {
    queryToRedirect = {
      ...query,
      [CommonQueryParams.SaleType]: (saleType ?? DEFAULT_SALE_TYPE).toLowerCase(),
      [CommonQueryParams.SaleTypeInitialSearchParam]: undefined,
    };
  }

  if (queryToRedirect) {
    throw new Response('', {
      status: 301,
      headers: {
        Location: stringifyRouteUrl(
          {
            pathname: route,
            basePath: routeName!,
            query: queryToRedirect,
          },
          true,
        ),
      },
    });
  }

  return saleType;
};

const createMigratedRoute = (route: Routes, index: boolean, globalRouterContext?: RouterContext): RouteObject => ({
  path: convertSharedToReactRoute(route),
  shouldRevalidate: ({ currentUrl, nextUrl }) => currentUrl.pathname !== nextUrl.pathname,
  lazy: async () => {
    const Component: ApplicationPage = (await import(`../../pages${route}/index.tsx`)).default;
    const { preload, getMainLayoutProps, getMeta } = Component;

    return {
      Component,
      loader: async ({ params, request, context: _context }): Promise<PageLoaderData> => {
        const context: RouterContext = globalRouterContext ?? _context;
        const { queryClient, storeConfig, intl } = context;
        const url = new URL(request.url);
        const query: RouteQuery = convertReactToSharedQuery(params, url.searchParams);
        const platformOs = getPlatformOs({
          isPWA: getIsPWA(),
          isAndroid: parseUA(isSSR ? undefined : navigator.userAgent).isAndroid,
        });

        const saleType = getSaleType({
          cookie: isSSR ? request.headers.get('cookie') ?? undefined : undefined,
          storeConfig,
          route,
          query,
        });

        await Promise.all([
          await GetShopInfoEndpoint.preload(
            undefined,
            {
              headers: {
                StoreId: storeConfig.id.toString(),
              },
            },
            queryClient,
          ).then((shopInfo) => {
            if (isSSR && shopInfo?.deliveryZones) {
              // eslint-disable-next-line no-param-reassign
              shopInfo.deliveryZones = [];
            }
          }),
          preload?.(
            { headers: { [XSweedHeader.StoreId]: String(storeConfig.id) }, query, queryClient },
            {
              saleType,
              platformOs,
              homePageDealsCarouselIsEnabled: storeConfig.homePageDealsCarouselIsEnabled,
            },
          ),
        ]);

        return {
          mainLayoutProps: getMainLayoutProps?.(),
          meta: getMeta?.({
            queryClient,
            query,
            platformOs,
            saleType,
            intl,
            storeConfig,
            pathname: route,
          }),
        };
      },
    };
  },
  index,
});

const getRedirects = ({ isTruncatedShop }: RouterConfig): RouteObject[] => {
  const redirects = isTruncatedShop ? TRUNCATED_SHOP_REDIRECTS : REDIRECTS;
  return redirects.map(({ source, destination, permanent, queryMapper }) => ({
    path: convertSharedToReactRoute(source),
    loader: async ({ params, request }) => {
      const url = new URL(request.url);
      const _query: RouteQuery = convertReactToSharedQuery(params, url.searchParams);
      const query: RouteQuery = queryMapper ? await queryMapper(_query) : _query;
      if ('all' in query && Array.isArray(query.all)) {
        query['*'] = query.all.join('/');
        delete query.all;
      }
      return redirect(generatePath(convertSharedToReactRoute(destination), query), permanent ? 308 : 307);
    },
    index: source === Routes.Home,
  }));
};

const createRoutesDef = (routerConfig: RouterConfig, globalRouterContext?: RouterContext): RouteObject[] => {
  const _pages: RouteObject[] = Object.values(Routes).map((route) =>
    createMigratedRoute(route, route === Routes.Home, globalRouterContext),
  );

  const pages: RouteObject[] = [
    ...getRedirects(routerConfig),
    ..._pages,
    {
      path: `/*`,
      loader: () => {
        throw new Response('Not Found', { status: 404 });
      },
    },
  ];

  return [
    {
      element: <CoreLayout pages={pages} />,
      ErrorBoundary: RootRouterErrorPage,
      children: [
        {
          Component: AppLayout,
          ErrorBoundary: RouterErrorPage,
          loader: async ({ context: _context }) => {
            const context: RouterContext = globalRouterContext ?? _context;
            const { queryClient, storeConfig } = context;
            const shopInfo = await GetShopInfoEndpoint.preload(
              undefined,
              {
                headers: {
                  StoreId: storeConfig.id.toString(),
                },
              },
              queryClient,
            );
            if (isSSR && shopInfo && shopInfo.deliveryZones) {
              shopInfo.deliveryZones = [];
            }
            return null;
          },
          children: [
            {
              ErrorBoundary: RouterErrorPage,
              children: pages,
            },
          ],
        },
      ],
    },
  ];
};

const createRouterDefMemoized = memoize(createRoutesDef, (routerConfig) =>
  Object.entries(routerConfig).sort().toString(),
);

export { createRoutesDef, createRouterDefMemoized };
export type { RouterContext, RouterConfig };
